<template>
  <ValidationObserver v-slot="{ passes }">
    <v-form @submit.prevent="passes(roleUpdate)">
      <v-card>
        <v-card-title>
          Role update
        </v-card-title>
        <v-card-text>
          <ValidationProvider
            v-slot="{ errors }"
            name="display name"
            rules="required"
          >
            <v-text-field
              v-model="role.display_name"
              :error-messages="errors"
              label="Name"
              type="text"
              required
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="description"
            rules="required"
          >
            <v-text-field
              v-model="role.description"
              :error-messages="errors"
              label="Description"
              type="text"
              required
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="name"
            rules="required"
          >
            <v-text-field
              v-model="role.name"
              :error-messages="errors"
              label="System Name"
              type="text"
              required
            />
          </ValidationProvider>
          <v-text-field
            :label="$t('messages.created_at')"
            type="text"
            :value="role.created_at | moment('YYYY-MM-DD HH:mm:ss')"
            disabled
          />
          <v-text-field
            :label="$t('messages.updated_at')"
            type="text"
            :value="role.updated_at | moment('YYYY-MM-DD HH:mm:ss')"
            disabled
          />
          <v-treeview
            v-model="selected"
            :items="tree"
            dense
            activatable
            active-class="grey lighten-4 indigo--text"
            selected-color="indigo"
            open-all
            open-on-click
            selectable
          />
        </v-card-text>
        <v-card-actions class="pb-3">
          <v-spacer />
          <v-btn
            type="submit"
            color="primary"
            :loading="loading"
          >
            {{ $t('action.update') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </ValidationObserver>
</template>

<script>
import permissionsToTree from './index'
import authHeader from '@/utils'

export default {
  name: 'RoleUpdate',
  breadcrumb () {
    return {
      label: this.$t('action.edit') + ' ' + this.role.display_name
    }
  },
  data () {
    return {
      selected: [],
      loading: false,
      tree: [],
      role: {},
      permissions: null
    }
  },
  beforeMount () {
    this.getPermissions()
    this.getRoleByID()
  },
  methods: {
    getPermissions () {
      this.$http
        .get('/permissions/?take=50', { headers: authHeader() })
        .catch(error => {
          this.displayError(error)
        })
        .then(({ data }) => {
          this.tree = permissionsToTree(data.data)
        })
    },
    getRoleByID () {
      this.$http
        .get('/roles/?id[]=' + this.$route.params.id, { headers: authHeader() })
        .catch(error => this.displayError(error))
        .then(({ data: { data } }) => {
          if (!data[0]) {
            this.$router.push('/404')
          } else {
            this.role = data[0]
            this.selected = data[0].permissions.map(val => val.id)
          }
        })
    },
    roleUpdate () {
      this.loading = true
      this.$http
        .put('/roles/' + this.$route.params.id,
          { ...this.role, ...{ permission_id: this.selected.filter(val => val > 0) } },
          { headers: authHeader() }
        )
        .then(() => {
          this.$store.commit('setNotification', { color: 'success', message: this.$t('action.update_success') })
          this.$router.push({ name: 'dashboard.role' })
        })
        .catch(error => {
          this.loading = false
          this.displayError(error)
        })
    }
  }
}
</script>
